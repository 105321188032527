/*@tailwind base;*/

/**
 * Custom base styles
 */

html {
	font-size: 62.5%;
	font-family: 'Inter var', Roboto, Helvetica Neue, Arial, sans-serif;
	background-color: #121212;
	-webkit-font-smoothing: antialiased;
	text-size-adjust: 100%;
}

body {
	font-size: 13px;
	line-height: 1.4;
	overflow-x: hidden;
}

html,
body,
#root {
	position: relative;
	margin: 0;
	min-height: 100vh;
}

h1,
.h1 {
	font-size: 24px;
}

h2,
.h2 {
	font-size: 20px;
}

h3,
.h3 {
	font-size: 16px;
}

h4,
.h4 {
	font-size: 15px;
}

h5,
.h5 {
	font-size: 13px;
}

h6,
.h6 {
	font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
	z-index: 99;
}

a[role='button'] {
	text-decoration: none;
}

[role='tooltip'] {
	z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
	::-webkit-scrollbar {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0);
	}

	::-webkit-scrollbar:hover {
		width: 8px;
		height: 8px;
		background-color: rgba(0, 0, 0, 0.06);
	}

	::-webkit-scrollbar-thumb {
		border: 2px solid transparent;
		border-radius: 20px;
	}

	::-webkit-scrollbar-thumb:active {
		border-radius: 20px;
	}
}

form label {
	z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
	transition: none !important;
	animation: none !important;
}

button:focus {
	outline: none;
}

/* Removes webkit's autofill backgorund color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transitiondelay: 9999s;
	transitionproperty: background-color, color;
}

:focus {
	outline-color: transparent;
}

/*fullcalendar Fix*/
.fc-scrollgrid-section-liquid {
	height: 1px !important;
}

.podio-button {
	background: #5092bd;
	background-image: url('https://d2cmuesa4snpwn.cloudfront.net/images/podio_top.svg');
	background-repeat: no-repeat;
	background-position: center;
	width: 6em;
}

.rule-builder-condition-container-input {
	position: absolute;
}

.MuiFormHelperText-root .MuiFormHelperText-contained .Mui-error .MuiFormHelperText-filled {
}

.rule-builder-group-header {
	height: 48px;
}
.rule-builder-group-container {
	padding-bottom: 0.1876em;
}

.rule-builder-condition-container-content {
	padding: 4px;
}
.rule-builder-condition-container-content:last-child {
	padding: 4px;
}

.rule-builder-group-vline {
	border-style: dotted;
	border-color: #eaeaea;
	border-width: 0 2px 0 0;
}

.rule-builder-group-vline-limiter {
}

.rule-builder-group-hline {
	border-style: dotted;
	border-color: #eaeaea;
	border-width: 2px 0 0 0;
}
